import Vue from "vue";

import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import {
  faChevronLeft,
  faChevronCircleUp,
  faChevronCircleDown,
  faMale,
  faCaretDown,
  faCaretUp,
  faCaretRight,
  faPlusSquare,
  faAngleDoubleRight,
  faSquare,
  faRibbon
} from "@fortawesome/free-solid-svg-icons";

const FONTS = [
  faChevronLeft,
  faAngleDoubleRight,
  faChevronCircleUp,
  faChevronCircleDown,
  faMale,
  faCaretDown,
  faCaretUp,
  faCaretRight,
  faPlusSquare,
  faSquare,
  faRibbon
];
const COMPONENT_ID = "fa";

export default class FontAwesomeService {
  static addFonts() {
    library.add(...FONTS);
  }

  static registerComponent() {
    Vue.component(COMPONENT_ID, FontAwesomeIcon);
  }

  static init() {
    FontAwesomeService.addFonts();
    FontAwesomeService.registerComponent();
  }
}
