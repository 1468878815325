import { API_URL } from "@/common/config";
import LoadingService from "@/common/services/loading.service";
import AuthService from "@/common/services/auth.service";
import ToastService from "@/common/services/toast.service";
import Cookie from "js-cookie";

/**
 * Service to call HTTP request via Axios
 */
const ApiService = {
  /**
   * Send post request
   * @param resource
   * @param params
   * @param includeDefaultParams
   * @returns {Promise<{data: null, error: null}>}
   */
  async post(resource, params = {}, includeDefaultParams = true) {
    const url = `${API_URL}${resource}`;
    let defaultParams = {};
    let result = { data: null, error: null };
    const user = AuthService.getUser();
    const AKTIF_DONEM = Cookie.get("AKTIF_DONEM") || '';

    if (includeDefaultParams && user) {
      defaultParams = {
        KID: user.ID_KULLANICI,
        SES: user.SESSION,
        DONEMKOD : AKTIF_DONEM
      };
    }

    const loader = new LoadingService();
    loader.show();

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ ...defaultParams, ...params })
      });

      if (response.status < 400) {
        const json = await response.json();
        if (json.HATA !== "NULL") {
          throw json.HATA;
        } else {
          result.data = json.LISTE;
        }
      } else {
        throw await response.json();
      }
    } catch (error) {
      const { ExceptionMessage } = error;
      result.error = ExceptionMessage || "Beklenmedik bir hata oluştu";
      ToastService.error(result.error);
    }

    loader.hide();
    return result;
  }
};

export default ApiService;
