import bgRedBlue from "@/assets/media/bg/red-blue.png";
import bgBlueRed from "@/assets/media/bg/blue-red.png";
import egitimBilimleriLogo from "@/assets/media/logos/egitim-bilimleri-logo.png";
import { CORPORATIONS } from "@/common/config";

const BLUE = "#005db9";
const LIGHT_BLUE = "#00aeef";
const RED = "#de1c24";
const LIGHT_GRAY = "#bcbec0";
const GRAY = "#808184";
const ORANGE = "#f5821f";
const GRAY_WHITE = "#f3f3f5";
const GREEN = "#39b54a";
const LIGHT_GREEN = "#7bbd31";
const WHITE = "#ffffff";
const LIGHT_ORANGE = "#f7a032";
const ERA_FIRST = "#8cc63f";
const ERA_SECOND = "#faa21a";
const KULTUR_FIRST = "#01b7cd";
const KULTUR_SECOND = "#ed008c";

export const COLORS = {
  BLUE,
  LIGHT_BLUE,
  RED,
  LIGHT_GRAY,
  GRAY_WHITE,
  GRAY,
  ORANGE,
  GREEN,
  LIGHT_GREEN,
  LIGHT_ORANGE,
  WHITE,
  ERA_FIRST,
  ERA_SECOND,
  KULTUR_FIRST,
  KULTUR_SECOND
};

const defaultTheme = {
  styles: {
    reportTitle: {
      color: GRAY,
      backgroundColor: GRAY_WHITE,
      textTransform: "uppercase",
      borderBottom: "1px solid #ccc"
    },
    button: {
      color: GRAY,
      backgroundColor: GRAY_WHITE,
      textTransform: "uppercase",
      fontFamily: "GothamNarrowBlack"
    },
    activeButton: {
      color: WHITE,
      backgroundColor: ORANGE,
      textTransform: "uppercase",
      fontFamily: "GothamNarrowBlack"
    },
    radioLabel: {
      color: GRAY,
      textTransform: "uppercase",
      fontFamily: "GothamCondensedBold",
      fontSize: "1.6rem"
    },
    select: {
      backgroundColor: GRAY,
      textTransform: "uppercase",
      fontFamily: "GothamNarrowBlack",
      fontSize: "1.6rem",
      cursor: "pointer"
    },
    h1: {
      color: GRAY,
      textTransform: "uppercase",
      fontFamily: "GothamNarrowBlack"
    }
  },
  charts: {
    doughnut: {
      colors: [
        BLUE, // Doğru
        RED, // Yanlış
        GRAY // Boş
      ]
    },
    bar: {
      colors: [
        GRAY, // BDS-1
        ORANGE, // BDS-2
        LIGHT_BLUE // Genel
      ]
    },
    comparisonBar: {
      colors: [
        ORANGE, // Öğrenci Net
        LIGHT_BLUE // Sınav Net Ort.
      ]
    }
  },
  lineBackgroundImage: {
    first: bgBlueRed,
    second: bgRedBlue
  },
  logo: egitimBilimleriLogo,
  colors: { ...COLORS }
};

const ThemeService = {
  getTheme(corporationId) {
    switch (corporationId) {
      case CORPORATIONS.FOO:
        return { ...defaultTheme };
      case 345:
        break;
      default:
        return { ...defaultTheme };
    }
  }
};

export default ThemeService;
