// action types
export const SHOW_WARNING = "showWarning";

// mutation types
export const TOGGLE_WARNING = "toggleWarning";

const state = {
  showWarning: false
};

const mutations = {
  [TOGGLE_WARNING](state, show) {
    state.showWarning = show;
  }
};

const actions = {
  [SHOW_WARNING](context, show) {
    context.commit(TOGGLE_WARNING, show);
  }
};

export default {
  state,
  getters: {
    showLandScapeWarning(state) {
      return state.showWarning;
    }
  },
  actions,
  mutations
};
