export default {
  state: {
    showGainDetailId: null,
    selectedExamList: []
  },
  getters: {
    getGainDetailId(state) {
      return state.showGainDetailId;
    },
    getExamList(state) {
      return state.selectedExamList;
    }
  },
  mutations: {
    setGainDetailId(state, id) {
      state.showGainDetailId = id;
    },
    setExamList(state, list) {
      state.selectedExamList = list;
    }
  },
  actions: {
    updateGainDetailId({ commit }, id) {
      commit("setGainDetailId", id);
    },
    updateExamList({ commit }, list) {
      commit("setExamList", list);
    }
  }
};
