import Vue from "vue";

const EventBus = new Vue();

const ToastService = {
  error(content, options) {
    EventBus.$emit("SHOW_ERROR_TOAST", {
      content: content || "Beklenmedik bir hata meydana geldi.",
      options: {
        title: "Hata",
        variant: "danger",
        solid: true,
        appendToast: true,
        autoHideDelay: 1000 * 10,
        ...options
      }
    });
  },
  onError(func) {
    EventBus.$on("SHOW_ERROR_TOAST", func);
  }
};

export default ToastService;
