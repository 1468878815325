import ApiService from "@/common/services/api.service";

const RESOURCE_URL = "Kurum";

export default {
  async YetkiyeGoreKurumGetir() {
    const result = await ApiService.post(`${RESOURCE_URL}/YetkiyeGoreKurumGetir`);
    if (result.error) return [];
    return result.data;
  }
};
