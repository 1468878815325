import AuthService from "@/common/services/auth.service";
import { SET_CORPORATION_ID } from "@/store/theme.module";
import { getRoleById } from "@/common/utils";
import { isProduction } from "@/common/config";

// action types
export const VERIFY_AUTH = "verifyAuth";
export const LOGIN = "login";

// mutation types
export const SET_AUTH = "setUser";
export const SET_ERROR = "setError";

const state = {
  errors: null,
  user: {},
  isAuthenticated: AuthService.isAuthenticated()
};

const getters = {
  currentUser(state) {
    let role;
    if (state.user.ID_OGRENCI) {
      role = "Ö";
    } else if (state.user.ID_VELI) {
      role = "V";
    } else {
      role = "Y";
    }

    const nameWithRole = isProduction() ? state.user.AD : `${state.user.AD} (${role})`;

    return {
      ...state.user,
      NAME_WITH_ROLE: nameWithRole
    };
  },
  isAuthenticated(state) {
    return state.isAuthenticated;
  },
  role(state) {
    const { Yetkiler } = state.user;
    const [permission] = Yetkiler;
    if (!permission) {
      return null;
    }
    return getRoleById(permission.ID_YETKIGRUP);
  }
};

const actions = {
  [LOGIN](context, userMockKey) {
    // Switch between mock users
    return new Promise(resolve => {
      const user = AuthService.setUser(userMockKey);
      if (!user) {
        return;
      }
      context.commit(SET_AUTH, user);
      resolve(user);
    });
  },
  [VERIFY_AUTH](context) {
    return new Promise((resolve, reject) => {
      const user = AuthService.getUser();
      if (!user) {
        reject();
      } else {
        context.commit(SET_AUTH, user);
        context.dispatch(SET_CORPORATION_ID, user.ID_KULLANICI); // TODO: refactor
        resolve(user);
      }
    });
  }
};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  },
  [SET_AUTH](state, user) {
    state.isAuthenticated = true;
    state.user = user;
    state.errors = {};
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
