import Vue from "vue";
import VueCookies from 'vue-cookies';

// Global 3rd party plugins
import "bootstrap";
import "popper.js";
import "tooltip.js";
import "perfect-scrollbar";

// Vue 3rd party plugins
import i18n from "./common/plugins/vue-i18n";
import vuetify from "./common/plugins/vuetify";
import "./common/plugins/bootstrap-vue";
import "./common/plugins/perfect-scrollbar";
import "./common/plugins/highlight-js";
import "@babel/polyfill";

import App from "./App.vue";
import router from "./router";
import store from "./store";
import MockService from "./common/mock/mock.service";
import FontAwesomeService from "./common/services/font-awesome.service";
import ChartDataLabelsService from "./common/services/chartjs-datalabel.service";
import LoadingService from "./common/services/loading.service";
import { VERIFY_AUTH } from "./store/auth.module";

Vue.config.productionTip = false;

// Remove this to disable mock API
MockService.init();

// Full screen loading indicator
LoadingService.init();

// Enable fontawesome fonts
FontAwesomeService.init();

// Enable chart-js datalabel plugin
ChartDataLabelsService.init();

// Ensure we checked auth before each page load.
// NOTE: views/theme/Base.vue redirects to login if user is not authenticated

router.beforeEach((to, from, next) => {
  Promise.all([store.dispatch(VERIFY_AUTH)])
    .then(next)
    .catch(() => next());

  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});
Vue.use(VueCookies);

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: h => h(App)
}).$mount("#app");
