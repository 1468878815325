import ApiService from "@/common/services/api.service";

const RESOURCE_URL = "Sube";

export default {
  async YetkiyeGoreSubeGetir(corpId) {
    const params = { ID_KURUM: corpId };
    const result = await ApiService.post(`${RESOURCE_URL}/YetkiyeGoreSubeGetir`, params);
    if (result.error) return [];
    return result.data;
  },

  async SubeSinavTurleri(branchId) {
    const params = { ID_SUBE: branchId };
    const result = await ApiService.post(`${RESOURCE_URL}/SubeSinavTurleri`, params);
    if (result.error) return [];
    return result.data;
  },

  async SubeSinavlari({ branchId, examTypeId }) {
    const params = { ID_SUBE: branchId, ID_SINAVTUR: examTypeId };
    const result = await ApiService.post(`${RESOURCE_URL}/SubeSinavlari`, params);
    if (result.error) return [];
    return result.data;
  }
};
