import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export default new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      redirect: "/",
      component: () => import("@/views/theme/Base"),
      children: [
        {
          path: "",
          name: "home",
          component: () => import("@/views/pages/karnem/Home.vue")
        }
      ]
    },
    {
      path: "/OgrenciKazanim", 
      component: () => import("@/views/pages/da/Index"),
      children: [
        {
          name: "ogrencikazanim",
          path: "/ogrencikazanim",
          component: () => import("@/views/pages/da/OgrenciKazanim")
        }
      ]
      
      
    },
    {
      path: "/error",
      name: "error",
      component: () => import("@/views/pages/error/Error.vue"),
      children: [
        {
          path: "error-1",
          name: "error-1",
          component: () => import("@/views/pages/error/Error-1.vue")
        },
        {
          path: "error-2",
          name: "error-2",
          component: () => import("@/views/pages/error/Error-2.vue")
        },
        {
          path: "error-3",
          name: "error-3",
          component: () => import("@/views/pages/error/Error-3.vue")
        },
        {
          path: "error-4",
          name: "error-4",
          component: () => import("@/views/pages/error/Error-4.vue")
        },
        {
          path: "error-5",
          name: "error-5",
          component: () => import("@/views/pages/error/Error-5.vue")
        },
        {
          path: "error-6",
          name: "error-6",
          component: () => import("@/views/pages/error/Error-6.vue")
        }
      ]
    },
    {
      path: "/",
      component: () => import("@/views/pages/auth/Auth"),
      children: [
        {
          name: "login",
          path: "/login",
          component: () => import("@/views/pages/auth/Login")
        }
      ]
    },
    {
      path: "*",
      redirect: "/404"
    },
    {
      // the 404 route, when none of the above matches
      path: "/404",
      name: "404",
      component: () => import("@/views/pages/error/Error-1.vue")
    }
  ]
});
