import { KurumAPI, SubeAPI, SinavAPI, SinifAPI, OgrenciAPI } from "@/common/api";
// import { REPORT_VARIANTS } from "@/common/config";
import { REPORT_VARIANTS, ALLOWED_EXAM_TYPES } from "@/common/config";
import { log } from "@/common/utils";

// action types
export const LIST = "list";
export const RESET = "reset";
export const SET = "set";

// mutation types
export const SET_FORM = "setForm";

const INITIAL_STATE = {
  variant: REPORT_VARIANTS.STUDENT,
  donem: null,
  donems: [],
  corp: null,
  corps: [],
  branch: null,
  branches: [],
  class: null,
  classes: [],
  student: null,
  students: [],
  examType: null,
  examTypes: [],
  exam: null,
  exams: []
};

const state = { ...INITIAL_STATE };

function getSelected(state) {
  const {
    donem,
    donems,
    corp,
    corps,
    branch,
    branches,
    class: _class,
    classes,
    student,
    students,
    examType,
    examTypes,
    exam,
    exams
  } = state;
  return {
    donem: donems.find(c => c.DONEMKOD === donem) || {},
    corp: corps.find(c => c.ID_KURUM === corp) || {},
    branch: branches.find(b => b.ID_SUBE === branch) || {},
    class: classes.find(c => c.ID_SINIF === _class) || {},
    student: students.find(s => s.ID_KULLANICI === student) || {},
    examType: examTypes.find(e => e.ID_SINAVTUR === examType) || {},
    exam: exams.find(e => e.ID_SINAVBILGI === exam) || {}
  };
}

const getters = {
  form(state) {
    return {
      ...state,
      selected: getSelected(state)
    };
  }
};

const actions = {
  async [LIST](context, { key, params }) {
    let data = [];
    switch (key) {
      case "donems": {
        //data = await KurumAPI.YetkiyeGoreKurumGetir();
        data = [
          { DONEMKOD: 2019, AD: "2019" },
          { DONEMKOD: 2020, AD: "2020" },
          { DONEMKOD: 2021, AD: "2021" }
        ];
        break;
      }
      case "corps": {
        data = await KurumAPI.YetkiyeGoreKurumGetir();
        break;
      }
      case "branches": {
        const { corpId } = params;
        data = await SubeAPI.YetkiyeGoreSubeGetir(corpId);
        break;
      }
      case "classes": {
        const { branchId } = params;
        data = await SinifAPI.YetkiyeGoreSinifGetir(branchId);
        break;
      }
      case "students": {
        const { classId } = params;
        data = await OgrenciAPI.SinifaGoreOgrenciGetir(classId);
        break;
      }
      case "examTypes": {
        const { userId, classId, branchId } = params;
        if (userId) {
          data = await SinavAPI.OgrenciSinavTuruGenel(userId);
        } else if (classId) {
          data = await SinifAPI.SinifSinavTurleri(classId);
        } else if (branchId) {
          data = await SubeAPI.SubeSinavTurleri(branchId);
        } else {
          log("Invalid parameter. Request won't be send to list exam types.");
        }

        data = data
          .filter(examType => {
            console.log( ALLOWED_EXAM_TYPES.indexOf(examType.ID_SINAVTUR) !== -1);
            return ALLOWED_EXAM_TYPES.indexOf(examType.ID_SINAVTUR) !== -1;
          })
          .map(examType => {
            const { KISAAD } = examType;
            if (KISAAD === "SBS") {
              return {
                ...examType,
                KISAAD: "BDS"
              };
            }

            if (KISAAD === "YKS") {
              return {
                ...examType,
                KISAAD: "AYT"
              };
            }

            return { ...examType };
          });
        break;
      }
      case "exams": {
        const { userId, examTypeId, classId, branchId } = params;
        if (userId) {
          data = await SinavAPI.OgrenciSinavTurSecimineGoreSinavlari(userId, examTypeId);
          data = data.map(exam => {
            return {
              ...exam,
              disabled: exam.DURUM === "girmedi"
            };
          });
        } else if (classId && branchId) {
          data = await SinifAPI.SinifSinavlari({ classId, branchId, examTypeId });
          data = data.map(exam => {
            return {
              ...exam,
              AD: exam.SINAVAD
            };
          });
        } else if (branchId && !classId) {
          data = await SubeAPI.SubeSinavlari({ branchId, examTypeId });
          data = data.map(exam => {
            return {
              ...exam,
              AD: exam.SINAVAD
            };
          });
        } else {
          log("Invalid parameter. Request won't be send to list exams.");
        }
        break;
      }
    }
    context.commit(SET_FORM, { key, data });
  },
  [RESET](context) {
    const excludeKeys = ["variant"];
    Object.keys({ ...INITIAL_STATE }).forEach(key => {
      if (excludeKeys.indexOf(key) !== -1) return;
      context.commit(SET_FORM, { key, data: INITIAL_STATE[key] });
    });
  },
  [SET](context, { key, data }) {
    context.commit(SET_FORM, { key, data });
  }
};

const mutations = {
  [SET_FORM](state, { key, data }) {
    state[key] = data;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
