import cookie from "js-cookie";

import mockAuthData from "@/common/mock/auth";

const AUTH_COOKIE_KEY = "s";

const AuthService = {
  isAuthenticated() {
    return !!AuthService.getUser();
  },
  getUser() {
    let user;
    user = cookie.get(AUTH_COOKIE_KEY) || null;
    user = JSON.parse(user);

    if (!user) {
      return;
    }
    return user;
  },
  setUser(key = "MANAGER") {
    const user = mockAuthData[key];
    cookie.set(AUTH_COOKIE_KEY, user);
    return user;
  }
};

export default AuthService;
