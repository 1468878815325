import ThemeService from "@/common/services/theme.service";

// action types
export const SET_CORPORATION_ID = "setCorporationId";

// mutation types

export default {
  state: {
    corporationId: null
  },
  getters: {
    theme(state) {
      return ThemeService.getTheme(state.corporationId);
    }
  },
  actions: {
    [SET_CORPORATION_ID](state, payload) {
      state.commit(SET_CORPORATION_ID, payload);
    }
  },
  mutations: {
    [SET_CORPORATION_ID](state, payload) {
      state.corporationId = payload;
    }
  }
};
