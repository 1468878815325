import ApiService from "@/common/services/api.service";

const RESOURCE_URL = "Ogrenci";

export default {
  async SinifaGoreOgrenciGetir(classId) {
    const params = { ID_SINIF: classId };
    const result = await ApiService.post(`${RESOURCE_URL}/SinifaGoreOgrenciGetir`, params);
    if (result.error) return [];
    return result.data.map(student => {
      return {
        ...student,
        ADSOYAD: `${student.AD} ${student.SOYAD}`
      };
    });
  }
};
