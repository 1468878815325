import ApiService from "@/common/services/api.service";

const RESOURCE_URL = "Sinif";

export default {
  async YetkiyeGoreSinifGetir(branchId) {
    const params = { ID_SUBE: branchId };
    const result = await ApiService.post(`${RESOURCE_URL}/YetkiyeGoreSinifGetir`, params);
    if (result.error) return [];
    return result.data;
  },

  async SinifSinavTurleri(classId) {
    const params = { ID_SINIF: classId };
    const result = await ApiService.post(`${RESOURCE_URL}/SinifSinavTurleri`, params);
    if (result.error) return [];
    return result.data;
  },

  async SinifSinavlari({ branchId, classId, examTypeId }) {
    const params = { ID_SINIF: classId, ID_SUBE: branchId, ID_SINAVTUR: examTypeId };
    const result = await ApiService.post(`${RESOURCE_URL}/SinifSinavlari`, params);
    if (result.error) return [];
    return result.data;
  }
};
