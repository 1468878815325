export const API_URL = "https://www.digitalayna.com/KARNEMAPI/api/";
export default API_URL;
export const LIVE_URL = "karnem.digitalayna.com";

export const CORPORATIONS = {
  FOO: 43851 // TODO: change with real corp id
};

export const ROLES = {
  STUDENT: "STUDENT",
  PARENT: "PARENT",
  MANAGER: "MANAGER"
};

export const EXAM_TYPES = {
  SBS: 3, // KISAAD: ID_SINAVTUR
  BDS: 9,
  TYT: 7,
  YKS: 8 // AYT
};

export const ALLOWED_EXAM_TYPES = [EXAM_TYPES.SBS, EXAM_TYPES.YKS, EXAM_TYPES.TYT];

export function isProduction() {
  return window.location.host === LIVE_URL;
}

export const REPORT_VARIANTS = {
  STUDENT: "student",
  CORP: "corp",
  CLASS: "class"
};

export const YKS_PERIODIC_TABLE_CATEGORIES = {
  SCIENCE: 1,
  SOCIAL: 2,
  EW: 3
};
