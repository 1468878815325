import Vue from "vue";

import Loading from "vue-loading-overlay";
// Import stylesheet
import "vue-loading-overlay/dist/vue-loading.css";

export default class LoadingService {
  constructor(config = {}) {
    this.config = { color: "#005db9", loader: "bars", ...config };
    this.loader = Vue.$loading;
  }

  static init() {
    Vue.use(Loading);
  }

  show() {
    this.loader = this.loader.show(this.config);
  }

  hide() {
    this.loader.hide();
  }
}
