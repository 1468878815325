import * as _ from "lodash";

import ApiService from "@/common/services/api.service";
import { listToApiString } from "@/common/utils";

const RESOURCE_URL = "Sinav";

function mergeGainsBy({ data, idKey, titleKey }) {
  const merged = {};
  const inital = {
    id: null,
    title: "",
    total: 0,
    correct: 0,
    wrong: 0,
    empty: 0,
    percent: 0,
    unitId: null,
    topicId: null,
    gainId: null
  };

  Object.keys(data).forEach(unitId => {
    const unit = data[unitId];
    merged[unitId] = unit.reduce(
      (accumulator, currentValue) => {
        const correct = accumulator.correct + currentValue.D;
        const total = accumulator.total + currentValue.SS;
        const percent = Math.floor((100 * correct) / total);

        return {
          id: currentValue[idKey],
          title: currentValue[titleKey],
          total,
          correct,
          wrong: accumulator.wrong + currentValue.Y,
          empty: accumulator.empty + currentValue.B,
          percent,
          unitId: currentValue.ID_UNITE,
          topicId: currentValue.ID_KONU,
          gainId: currentValue.ID_KAZANIM
        };
      },
      { ...inital }
    );
  });

  return Object.values(merged);
}

function mapComparisonDataByLesson(studentNetByExam, generalNetByExam, isGrade8 = false) {
  const lessonDict = {
    TRKN: {
      _generalNetKey: "TRK_SNVORTN",
      totalQuestionKey: "TRSS",
      totalQuestion: 20,
      lesson: "Türkçe",
      exams: [],
      averageStudentNet: 0,
      averageGeneralNet: 0,
      orders: {
        desktop: 1,
        tablet: 1,
        mobile: 1
      }
    },
    SOSN: {
      _generalNetKey: "SOS_SNVORTN",
      totalQuestionKey: "SOSSS",
      totalQuestion: 10,
      lesson: isGrade8 ? "Ink.Tar.ve A." : "Sosyal Bilgiler",
      exams: [],
      averageStudentNet: 0,
      averageGeneralNet: 0,
      orders: {
        desktop: 4,
        tablet: 2,
        mobile: 2
      }
    },
    INGN: {
      _generalNetKey: "ING_SNVORTN",
      totalQuestionKey: "INGSS",
      totalQuestion: 10,
      lesson: "Yabancı Dil",
      exams: [],
      averageStudentNet: 0,
      averageGeneralNet: 0,
      orders: {
        desktop: 6,
        tablet: 4,
        mobile: 4
      }
    },
    DINN: {
      _generalNetKey: "DIN_SNVORTN",
      totalQuestionKey: "DINSS",
      totalQuestion: 10,
      lesson: "Din Kültürü",
      exams: [],
      averageStudentNet: 0,
      averageGeneralNet: 0,
      orders: {
        desktop: 5,
        tablet: 3,
        mobile: 3
      }
    },
    MATN: {
      _generalNetKey: "MAT_SNVORTN",
      totalQuestionKey: "MATSS",
      totalQuestion: 20,
      lesson: "Matematik",
      exams: [],
      averageStudentNet: 0,
      averageGeneralNet: 0,
      orders: {
        desktop: 2,
        tablet: 5,
        mobile: 5
      }
    },
    FENN: {
      _generalNetKey: "FEN_SNVORTN",
      totalQuestionKey: "FENSS",
      totalQuestion: 20,
      lesson: "Fen Bilimleri",
      exams: [],
      averageStudentNet: 0,
      averageGeneralNet: 0,
      orders: {
        desktop: 3,
        tablet: 6,
        mobile: 6
      }
    }
  };

  studentNetByExam.forEach(studentNetData => {
    const generalNetData = generalNetByExam.find(
      gNetData => gNetData.ID_SINAVBILGI === studentNetData.ID_SINAVBILGI
    );

    Object.keys(lessonDict).forEach(lessonKey => {
      const lessonStudentNet = studentNetData[lessonKey];

      const exam = studentNetData.SINAVAD;
      const generalNetKey = lessonDict[lessonKey]._generalNetKey;

      lessonDict[lessonKey].exams.push({
        exam,
        studentNet: lessonStudentNet,
        generalNet: generalNetData[generalNetKey]
      });
    });

    Object.values(lessonDict).forEach(lessonData => {
      const examsLen = lessonData.exams.length || 1;

      let generalStudentNet = 0;
      lessonData.exams.forEach(exam => {
        generalStudentNet += exam.studentNet;
      });

      let generalNet = 0;
      lessonData.exams.forEach(exam => {
        generalNet += exam.generalNet;
      });

      lessonData.averageStudentNet = generalStudentNet / examsLen;
      lessonData.averageGeneralNet = generalNet / examsLen;
    });
  });

  return Object.values(lessonDict);
}

function grade8(gains) {
  // kazanımların listelendiği verilerin içinde Ink.Tar.ve A. dersi varsa 8. sınıftır.
  // Yoksa 4, 5, 6 olabilir. Ders adı Sosyal Bilgiler olur.
  return !!gains.find(gain => gain.DERSAD === "Ink.Tar.ve A.");
}

function parseBestScoreFromComparison(dataByExam) {
  return dataByExam
    .sort((a, b) => {
      const { YD: aPercent } = a;
      const { YD: bPercent } = b;

      if (aPercent < bPercent) return -1;
      if (aPercent > bPercent) return 1;

      return 0;
    })
    .map(examData => {
      const { GNLPUAN700, YD, SINAVAD } = examData;
      return {
        exam: SINAVAD,
        score: GNLPUAN700,
        percent: YD
      };
    });
}

export default {
  /**
   * Returns exam types such as SBS, TYT, YKS...
   * @param userId
   * @returns {Promise<*[]|*>}
   */
  async OgrenciSinavTuruGenel(userId) {
    const params = { ID_KULLANICI: userId };
    const result = await ApiService.post(`${RESOURCE_URL}/OgrenciSinavTuruGenel`, params);
    if (result.error) return [];
    return result.data;
  },

  /**
   * Returns exams
   * @param userId
   * @param examTypeId
   * @returns {Promise<*[]|*>}
   */
  async OgrenciSinavTurSecimineGoreSinavlari(userId, examTypeId) {
    const params = { ID_KULLANICI: userId, ID_SINAVTUR: examTypeId };
    const result = await ApiService.post(
      `${RESOURCE_URL}/OgrenciSinavTurSecimineGoreSinavlari`,
      params
    );
    if (result.error) return [];
    return result.data;
  },

  /**
   * Returns result of exam
   * @param userId
   * @param examId
   * @param action Between 1-6
   * @returns {Promise<*[]|*>}
   */
  async OgrenciSinavTuruneGoreSinavlari(userId, examId, action = 1) {
    const params = { ID_SINAVBILGI: examId, ISLEM: action, ID_KULLANICI: userId };
    const result = await ApiService.post(`${RESOURCE_URL}/OgrenciSinavTuruneGoreSinavlari`, params);
    if (result.error) return [];
    return result.data;
  },

  async OgrenciKazanimKarnesiTumVeriler({ userId, examIdList, section }) {
    const params = {
      ID_KULLANICI: userId,
      ID_SINAVBILGILER: listToApiString(examIdList),
      BOLUM: section
    };
    const result = await ApiService.post(`${RESOURCE_URL}/OgrenciKazanimKarnesiTümVeriler`, params);
    if (result.error) return [];
    return result.data;
  },

  /**
   * Return total net info by passed exams
   * @param userId
   * @param examIdList
   * @param isGrade8
   * @returns {Promise<{correct: number, studentNet: number, byLesson: [], studentScore: (*|number), averageNet: number, wrong: number, empty: number, correctnessPercent: number}>}
   */
  async totalNetsByExam({ userId, examIdList, isGrade8 = false }) {
    const [netsByLesson] = await this.OgrenciKazanimKarnesiTumVeriler({
      userId,
      examIdList,
      section: 2
    });
    const [generalNets] = await this.OgrenciKazanimKarnesiTumVeriler({
      userId,
      examIdList,
      section: 4
    });

    const { TD, TY, TB, TN, TZ } = netsByLesson;

    const result = {
      correct: TD,
      wrong: TY,
      empty: TB,
      studentNet: TN,
      averageNet: 0,
      studentScore: netsByLesson.PUAN_SecilenSNVGNLN || 0,
      correctnessPercent: TZ,
      byLesson: []
    };

    const lessons = [
      { key: "TRK", lesson: "Türkçe" },
      { key: "MAT", lesson: "Matematik" },
      { key: "FEN", lesson: "Fen Bilimleri" },
      { key: "SOS", lesson: isGrade8 ? "Ink.Tar.ve A." : "Sosyal Bilgiler" },
      { key: "ING", lesson: "Yabancı Dil" },
      { key: "DIN", lesson: "Din Kültürü" }
    ];
    lessons.forEach(lesson => {
      const { key: lessonKey, lesson: lessonName } = lesson;
      const correctKey = `${lessonKey}_SecilenSNVGNLD`;
      const wrongKey = `${lessonKey}_SecilenSNVGNLY`;
      const emptyKey = `${lessonKey}_SecilenSNVGNLB`;
      const netKey = `${lessonKey}_SecilenSNVGNLN`;
      const averageKey = `${lessonKey}_GENELSNVORT`;

      const total = netsByLesson[correctKey] + netsByLesson[wrongKey] + netsByLesson[emptyKey];
      result.byLesson.push({
        lesson: lessonName,
        correct: netsByLesson[correctKey],
        wrong: netsByLesson[wrongKey],
        empty: netsByLesson[emptyKey],
        studentNet: netsByLesson[netKey],
        averageNet: generalNets[averageKey],
        correctnessPercent: Math.floor((100 * netsByLesson[correctKey]) / total)
      });
    });

    const totalQuestionLen = result.correct + result.wrong + result.empty;
    result.correctnessPercent = Math.floor((100 * result.correct) / totalQuestionLen);

    result.averageNet =
      generalNets.TRK_GENELSNVORT +
      generalNets.MAT_GENELSNVORT +
      generalNets.FEN_GENELSNVORT +
      generalNets.SOS_GENELSNVORT +
      generalNets.ING_GENELSNVORT +
      generalNets.DIN_GENELSNVORT;

    return result;
  },

  async gainsTreeByExam({ userId, examIdList }) {
    const allGains = await this.OgrenciKazanimKarnesiTumVeriler({ userId, examIdList, section: 7 });
    const isGrade8 = grade8(allGains);
    const exams = await this.OgrenciKazanimKarnesiTumVeriler({ userId, examIdList, section: 6 });

    const { byLesson: netsByLesson } = await this.totalNetsByExam({ userId, examIdList, isGrade8 });

    const gainsByLesson = netsByLesson.map(lesson => {
      const gainsBelongToLesson = allGains.filter(gain => {
        const gainLesson = gain.DERSAD === "İngilizce" ? "Yabancı Dil" : gain.DERSAD;
        return gainLesson === lesson.lesson;
      });
      let units = _.groupBy(gainsBelongToLesson, "ID_UNITE");
      let topics = _.groupBy(gainsBelongToLesson, "ID_KONU");
      let gains = _.groupBy(gainsBelongToLesson, "ID_KAZANIM");

      units = mergeGainsBy({
        data: units,
        idKey: "ID_UNITE",
        titleKey: "UNITE"
      });
      topics = mergeGainsBy({
        data: topics,
        idKey: "ID_KONU",
        titleKey: "KONU"
      });
      gains = mergeGainsBy({
        data: gains,
        idKey: "ID_KAZANIM",
        titleKey: "KAZANIM"
      });

      gains = gains.map(gain => {
        return {
          ...gain,
          exams: exams.filter(exam => gain.gainId == exam.ID_U)
        };
      });
      topics = topics.map(topic => {
        return {
          ...topic,
          gains: gains.filter(gain => gain.topicId === topic.id)
        };
      });
      units = units.map(unit => {
        return {
          ...unit,
          topics: topics.filter(topic => topic.unitId === unit.id)
        };
      });

      return {
        ...lesson,
        total: units.reduce((accumulator, currentValue) => accumulator + currentValue.total, 0),
        correct: units.reduce((accumulator, currentValue) => accumulator + currentValue.correct, 0),
        wrong: units.reduce((accumulator, currentValue) => accumulator + currentValue.wrong, 0),
        empty: units.reduce((accumulator, currentValue) => accumulator + currentValue.empty, 0),
        units
      };
    });

    return gainsByLesson;
  },

  async OgrenciKiyasAnaliziTumVeriler({ userId, examIdList, section, action }) {
    // ıslem 2, bolum 1 ogrenci net bilgisi ve ogrenci puan
    // ıslem 2, bolum 2 ogrenci ortalama net bilgisi ve ogrenci ortalama puan
    // ıslem 2, bolum 3 genel net bilgisi ve genel puan
    // islem 2, bolum 4 sınav ortalama net ve ortalama puan
    const params = {
      ID_KULLANICI: userId,
      ID_SINAVBILGILER: listToApiString(examIdList),
      BOLUM: section,
      ISLEM: action
    };
    return await ApiService.post(`${RESOURCE_URL}/OgrenciKiyasAnaliziTümVeriler`, params);
  },

  async comparisonScoreData({ userId, examIdList }) {
    const { data: studentScoreByExam = [] } = await this.OgrenciKiyasAnaliziTumVeriler({
      userId,
      examIdList,
      action: 2,
      section: 1
    });

    let { data: studentAverageData = [] } = await this.OgrenciKiyasAnaliziTumVeriler({
      userId,
      examIdList,
      action: 2,
      section: 2
    });
    [studentAverageData = {}] = studentAverageData;

    const { data: generalScoreByExam = [] } = await this.OgrenciKiyasAnaliziTumVeriler({
      userId,
      examIdList,
      action: 2,
      section: 3
    });

    let { data: generalAverageData = [] } = await this.OgrenciKiyasAnaliziTumVeriler({
      userId,
      examIdList,
      action: 2,
      section: 4
    });
    [generalAverageData = {}] = generalAverageData;

    const dataByExam = studentScoreByExam.map(sData => {
      // Return student score and general score by exam
      const generalData = generalScoreByExam.find(
        gData => gData.ID_SINAVBILGI === sData.ID_SINAVBILGI
      );

      return {
        exam: sData.SINAVAD,
        studentScore: sData.GNLPUAN700,
        generalScore: generalData.ORT_SINAVPUAN
      };
    });

    const {
      TRK_GENELSNVORT,
      MAT_GENELSNVORT,
      FEN_GENELSNVORT,
      SOS_GENELSNVORT,
      ING_GENELSNVORT
    } = generalAverageData;
    const generalAverageNet =
      TRK_GENELSNVORT + MAT_GENELSNVORT + FEN_GENELSNVORT + SOS_GENELSNVORT + ING_GENELSNVORT;

    const {
      TRK_SecilenSNVGNLN,
      MAT_SecilenSNVGNLN,
      FEN_SecilenSNVGNLN,
      SOS_SecilenSNVGNLN,
      DIN_SecilenSNVGNLN
    } = studentAverageData;
    const studentAverageNet =
      TRK_SecilenSNVGNLN +
      MAT_SecilenSNVGNLN +
      FEN_SecilenSNVGNLN +
      SOS_SecilenSNVGNLN +
      DIN_SecilenSNVGNLN;

    const allGains = await this.OgrenciKazanimKarnesiTumVeriler({ userId, examIdList, section: 7 });
    const isGrade8 = grade8(allGains);

    const scoresByExam = parseBestScoreFromComparison(studentScoreByExam);
    return {
      byExam: dataByExam,
      scoresByExam,
      studentAverageScore: studentAverageData.PUAN_SecilenSNVGNLN,
      generalAverageScore: generalAverageData.ORT_GENELSINAVPUAN,
      studentAverageNet,
      generalAverageNet,
      byLesson: mapComparisonDataByLesson(studentScoreByExam, generalScoreByExam, isGrade8)
    };
  },

  async SinifSinavAnaliziTumVeriler({ examId, classId, action }) {
    const params = {
      ID_SINAVBILGI: examId,
      ID_SINIF: classId,
      ISLEM: action
    };
    return await ApiService.post(`${RESOURCE_URL}/SinifSinavAnaliziTümVeriler`, params);
  },

  async SinifSinavAnaliziTumVerilerArka({ examId, classId, examTypeId, action }) {
    const params = {
      ID_SINAVBILGI: examId,
      ID_SINIF: classId,
      ISLEM: action,
      ID_SINAVTUR: examTypeId
    };
    return await ApiService.post(`${RESOURCE_URL}/SinifSinavAnaliziTümVerilerArka`, params);
  },

  async SinifKazanimKarnesiTumVeriler({ examIdList, classId, action }) {
    const params = {
      ID_SINAVBILGILER: listToApiString(examIdList),
      ID_SINIF: classId,
      ISLEM: action
    };
    return await ApiService.post(`${RESOURCE_URL}/SinifKazanimKarnesiTümVeriler`, params);
  },

  async SinifKiyasAnaliziTumVeriler({ examIdList, classId, action }) {
    const params = {
      ID_SINAVBILGILER: listToApiString(examIdList),
      ID_SINIF: classId,
      ISLEM: action
    };
    return await ApiService.post(`${RESOURCE_URL}/SinifKiyasAnaliziTümVeriler`, params);
  },

  async KurumSinavAnaliziTumVerilerOn({ examId, branchId, action }) {
    const params = {
      ID_SINAVBILGI: examId,
      ID_SUBE: branchId,
      ISLEM: action
    };
    return await ApiService.post(`${RESOURCE_URL}/KurumSinavAnaliziTümVerilerOn`, params);
  },

  async KurumSinavAnaliziTumVerilerArka({ examId, branchId, action }) {
    const params = {
      ID_SINAVBILGI: examId,
      ID_SUBE: branchId,
      ISLEM: action
    };
    return await ApiService.post(`${RESOURCE_URL}/KurumSinavAnaliziTümVerilerArka`, params);
  },

  async KurumPodyumAnaliziTumVeriler({ examId, scoreType = 0 }) {
    const params = {
      ID_SINAVBILGI: examId,
      PUANTUR: scoreType
    };
    return await ApiService.post(`${RESOURCE_URL}/KurumPodyumAnaliziTümVeriler`, params);
  },

  async KurumPeryodikDogruAnaliziTabloVeriler({ examId, branchId, section, category = 1 }) {
    const params = {
      ID_SINAVBILGI: examId,
      ID_SUBE: branchId,
      BOLUM: section,
      Alan: category
    };
    return await ApiService.post(`${RESOURCE_URL}/KurumPeryodikDogruAnaliziTabloVeriler`, params);
  },

  async KurumKazanimAnaliziTumVeriler({ examIdList, branchId, action, section }) {
    const params = {
      ID_SINAVBILGILER: listToApiString(examIdList),
      ID_SUBE: branchId,
      ISLEM: action,
      BOLUM: section
    };
    return await ApiService.post(`${RESOURCE_URL}/KurumKazanimAnaliziTümVeriler`, params);
  },

  async KurumKiyasAnaliziTumVeriler({ examIdList, branchId, action }) {
    const params = {
      ID_SINAVBILGILER: listToApiString(examIdList),
      ID_SUBE: branchId,
      ISLEM: action
    };
    return await ApiService.post(`${RESOURCE_URL}/KurumKiyasAnaliziTümVeriler`, params);
  }
};
