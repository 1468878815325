import { ROLES } from "./config";
import { isProduction } from "./config";

export function getColorForPercentage(pct, a = 1) {
  // https://stackoverflow.com/a/7128796/5524439
  const percentColors = [
    { pct: 0.0, color: { r: 0xff, g: 0x00, b: 0 } },
    { pct: 0.5, color: { r: 0xff, g: 0xff, b: 0 } },
    { pct: 1.0, color: { r: 0x00, g: 0xff, b: 0 } }
  ];
  let i = 1;
  for (; i < percentColors.length - 1; i++) {
    if (pct < percentColors[i].pct) {
      break;
    }
  }

  const lower = percentColors[i - 1];
  const upper = percentColors[i];
  const range = upper.pct - lower.pct;
  const rangePct = (pct - lower.pct) / range;
  const pctLower = 1 - rangePct;
  const pctUpper = rangePct;
  const color = {
    r: Math.floor(lower.color.r * pctLower + upper.color.r * pctUpper),
    g: Math.floor(lower.color.g * pctLower + upper.color.g * pctUpper),
    b: Math.floor(lower.color.b * pctLower + upper.color.b * pctUpper)
  };
  return `rgba(${color.r}, ${color.g}, ${color.b}, ${a})`;
}

export function getRoleById(permissionId) {
  /*
  YETKI_ID: YETKI_AD
  1: "Developer",
  3: "Personel",
  4: "Veli",
  5: "Öğrenci",
  14: "Öğretmen",
  15: "Rehber Öğretmen",
  18: "Eğitim Danışmanı",
  19: "Şube Yetkilisi",
  20: "Şube Müdürü",
  21: "Zümre Başkanı",
  22: "Kurum Yetkilisi",
  23: "Genel Yönetici",
  24: "Sınav Sorumlusu",
  45: "Mind Öğretmen",
  46: "Müdür Yardımcısı"
  */
  switch (permissionId) {
    case 4:
      return ROLES.PARENT;
    case 5:
      return ROLES.STUDENT;
    default:
      return ROLES.MANAGER;
  }
}

export function ptToPx(pt, onlyValue = false) {
  // 1 pt 1.3281472327365 px
  // https://everythingfonts.com/font/tools/units/pt-to-px
  const px = pt * 1.3281472327365;
  return onlyValue ? px : `${px}px`;
}

export function reFill(svgAsStr, color, base64 = true) {
  const pattern = /.cls-1{fill:.*;}+/g;
  const str = svgAsStr.replace(pattern, `.cls-1{fill:${color};}`);
  return base64 ? strToBase64(str) : str;
}

export function strToBase64(str, type = "image/svg+xml") {
  const btoa = window.btoa(str);
  return `data:${type};base64,${btoa}`;
}

export function listToApiString(l) {
  return `,${l.toString()},`;
}

export function log(...params) {
  if (isProduction()) {
    console.warn(params);
  }
}

export function isInt(n) {
  return Number.isInteger(n);
}

const isObj = o => o?.constructor === Object;

export function byDeviceWidth(props, fallback) {
  const {
    screen: { width }
  } = window;
  const { mobile, tablet, desktop } = props;

  if (width <= 768) {
    return isObj(mobile) ? { ...mobile } : fallback ? { ...props[fallback] } : mobile;
  }

  if (width <= 1200) {
    return isObj(tablet) ? { ...tablet } : fallback ? { ...props[fallback] } : tablet;
  }

  return isObj(desktop) ? { ...desktop } : fallback ? { ...props[fallback] } : desktop;
}